<template>
  <div>
    <el-card>
      <div class="flex flex-align-center flex-wrap">
        <div class="flex flex-align-center margin-right-10 margin-bottom-10">
          <div class="keyLabel">关键字：</div>
          <el-input v-model="keywords" style="width:220px" placeholder="主题名称"></el-input>
        </div>
        <el-button type="primary" class="margin-bottom-10" @click="currentPage=1,gettablelist()">查询</el-button>
        <buttonPermissions datas="takeTestAdd" class="margin-left-10">
          <el-button type="primary" class="margin-bottom-10" @click="toEdit({})">添加</el-button>
        </buttonPermissions>
      </div>
      <div class="margin-top-10">
        <el-table :data="tablelist" v-loading="tableloading">
          <el-table-column prop="Name" label="主题名称">
            <template slot-scope="scope">
              <div class="ellipsis">{{scope.row.Name}}</div>
            </template>
          </el-table-column>
          <el-table-column prop="ProductRangeDescription" label="主题推荐商品范围">
            <template slot-scope="scope">
              <div class="ellipsis">{{scope.row.ProductRangeDescription}}</div>
            </template>
          </el-table-column>
          <el-table-column prop="" label="测试人数">
            <template slot-scope="scope">
              <div>
                <el-button type="text" @click="toRecord(scope.row)" v-if="scope.row.TestCount>0">{{scope.row.TestCount}}</el-button>
                <div v-else>{{scope.row.TestCount}}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="AddTime" label="创建时间"></el-table-column>
          <el-table-column prop="" label="操作">
            <template slot-scope="scope">
              <div>
                <buttonPermissions datas="takeTestEdit" class="margin-right-10 margin-bottom-10">
                  <el-button type="text" @click="toEdit(scope.row)">编辑</el-button>
                </buttonPermissions>
                <buttonPermissions datas="takeTestPut" class="margin-right-10 margin-bottom-10">
                  <el-button type="text" @click="toPut(scope.row)">投放</el-button>
                </buttonPermissions>
                <buttonPermissions datas="takeTestDel" class="margin-right-10 margin-bottom-10">
                  <el-button type="text" style="color:#f56c6c" @click="toDel(scope.row)">删除</el-button>
                </buttonPermissions>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="margin-top-10" style="text-align:right" v-if="total">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="pagesize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
          </el-pagination>
        </div>
      </div>
    </el-card>

		<batch-cods :weiCodeVisivble="weiCodeShow" :weiCodeData="weiCodeData" @cancel="weiCodeShow = false"></batch-cods>
  </div>
</template>

<script>
import buttonPermissions from '@/components/buttonPermissions';
import batchCods from '@/components/bathCodes';
import {
  takeTestlist,
  takeTestdelete
} from "@/api/TurnTomySelf"
export default {
  components: {
    buttonPermissions,
    batchCods
  },
  data () {
    return {
      keywords:'',
      tablelist:[],
      tableloading:false,
      currentPage:1,
      pagesize:20,
      total:0,
      weiCodeShow:false,
      weiCodeData:{},
    }
  },
  mounted () {
    this.gettablelist()
  },
  methods: {
    toEdit(row){
      this.$router.push({
        path:'/market/takeTest/takeTestEdit',
        query:{
          Id:row.Id
        }
      })
    },
    toRecord(row){
      this.$router.push({
        path:'/market/takeTest/takeTestRecord',
        query:{
          Id:row.Id
        }
      })
    },
    toPut(row){
      this.weiCodeShow = true;
      this.weiCodeData = {
        Page: 'pageC/pages/goodnessTest/home',
        Scene: `id=${row.Id}`
      }
    },
    toDel(row){
      this.$confirm('是否确认删除本场测一测？删除后不可恢复', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        this.tableloading = true
        try{
          let res = await takeTestdelete({
            Id:row.Id,
          })
          if(res.IsSuccess){
            this.$message.success('删除成功')
            this.gettablelist()
          }
        }finally{
          this.tableloading = false
        }
      })
    },
    handleSizeChange(e){
      this.currentPage = 1
      this.pagesize = e
      this.gettablelist()
    },
    handleCurrentChange(e){
      this.currentPage = e
      this.gettablelist()
    },
    async gettablelist(){
      try{
        this.tableloading = true
        let data = {
          Keywords:this.keywords,
          Skip:(this.currentPage-1)*this.pagesize,
          Take:this.pagesize
        }
        let res = await takeTestlist(data)
        if(res.IsSuccess){
          this.tablelist = res.Result.Results
          this.total = res.Result.Total
        }
      }finally{
        this.tableloading = false
      }
    }
  }
}
</script>

<style>

</style>